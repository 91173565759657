import React,{useState} from "react";
import emailjs from 'emailjs-com'


export default function Contact({ bgColor }) {
  const initialState = {
    name: '',
    email: '',
    message: '',
  }
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_vyiq2u2', 'template_qznbhyg', e.target, 'aWQrFscv7lxiAoJnt'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <>
      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <h2>Contact Us</h2>
                
              </div>
              <div className="footer-address">
                <h6>
                  <strong>Head Office</strong>
                </h6>
                <p>Universitaetspark 1/1, 73525 <br/>Schwaebisch Gmuend, Germany </p>
                <ul>
                  <li>
                    <span>Phone: +49 7171 / 9226890</span>
                  </li>
                  <li>
                    <span>
                      Email:&nbsp;
                      <a href="mailto:contact@mail.eifi-tech.eu" >
                      contact@mail.eifi-tech.eu
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">
            <form
                name='sentMessage' validate onSubmit={handleSubmit}
                id="contactForm"
                className="contact-us-form"
              >
                <h5>Reach us quickly</h5>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                         type='text'
                         id='name'
                         value={name}
                         name='name'
                         className='form-control'
                         required
                         onChange={handleChange}
                        placeholder="Enter name"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        id='email'
                        value={email}
                        name='email'
                        className='form-control'
                        required
                        onChange={handleChange}
                        placeholder="Enter email"
                      />
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                    <textarea
                        name='message'
                        id='message'
                        className='form-control'
                        value={message}
                        placeholder='Message'
                        required
                        onChange={handleChange}
                        rows="7"
                        cols="25"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <button
                      type="submit"
                      className="btn solid-btn"
                      id="btnContactUs"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
