import React,{useContext, useEffect, useState} from "react";
import { blogData } from "../../data";
import { client } from "../team/client";
import {marked} from 'marked'
import DOMPurify from 'dompurify'

export const getHTMLData = (rawdata) => {
  const htmlString = marked(rawdata)
  const santizedHTMLString = DOMPurify.sanitize(htmlString)
  return santizedHTMLString
}

export default function ProjectRight() {
  const [projects, setProjects]=useState([])


  useEffect(()=>{
    const getAllEntries = async () =>{
        try { 
            await client.getEntries({
                content_type: "projects"
              }).then((entries) =>{
                console.log(entries)
                setProjects(entries)
              
            })
        }  catch(error){
            console.log("error");
        }
    }
    getAllEntries()
  },[])
  return (
    <>
    <section id="about" className="feature-section ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
         
          {projects.items?.map((post)=> (
              <>

               <div className="col-md-7" key={post.sys.id}>
              <div>
               <h4 style={{display:'inline-block'}}>
               {post.fields.title}
                   </h4>
                   
                   </div>  
                <p> <div dangerouslySetInnerHTML= {{__html: post.fields.description}}/> </p>
                <a className='btn solid-btn' style={{color:'#29659d',padding:'2px 8px',color:'#3074a9',backgroundColor:'white',borderRadius:'5px',fontWeight:'500'}} href={post.fields.link} target="_blank">Read more</a>
              
               </div>
              
               <div className="col-md-4">
                 <div className="feature-contents section-heading">
                 <div className="download-img">
                  <img
                   style={{borderRadius:'20px'}}
                    src={post.fields.image.fields.file.url}
                    class="card-img-top position-relative"
                    alt="blog"
                  />
                 </div>
                 </div>
              
               </div>
             
               <hr style={{marginBottom:'40px',width:'98%'}}/>
               </>
            ))}
                
          </div>
        </div>
      </section>
    </>
  );
}
