import React,{useContext, useEffect, useState} from "react";
import { client } from "../team/client";
import {marked} from 'marked'
import DOMPurify from 'dompurify'

export const getHTMLData = (rawdata) => {
    const htmlString = marked(rawdata)
    const santizedHTMLString = DOMPurify.sanitize(htmlString)
    return santizedHTMLString
}

const FeaturesTwo = () => {
  const [title1, setTitle1] = React.useState(false);
  const [description1, setDescription1] = React.useState(false);


  useEffect(()=>{
    const getAllEntries = async () =>{
        try { 
            await client.getEntries({
                content_type: "landingPage"
              }).then((entries) =>{
                setTitle1(entries.items[0].fields.title1)
                setDescription1(getHTMLData(entries.items[0].fields.section1Description))
             

               
            })
        }  catch(error){
            console.log("error");
        }
    }
    getAllEntries()
  },[])
  return (
    <>
      <section id="about" className="feature-section ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
            <h2>
                 {title1}
                </h2>
                <p><div dangerouslySetInnerHTML= {{__html: description1}}/></p>
              
            </div>
            <div className="col-md-4">
              <div className="feature-contents section-heading">
              <div className="download-img">
                <img  style={{borderRadius:'20px'}}
                  src="assets/img/teamwork.jpg"
                  alt="download"
                  className="img-fluid"
                />
              </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturesTwo;
