import React,{useContext, useEffect, useState} from "react";
import { client } from "../team/client";
import {marked} from 'marked'
import DOMPurify from 'dompurify'
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";


export const getHTMLData = (rawdata) => {
  const htmlString = marked(rawdata)
  const santizedHTMLString = DOMPurify.sanitize(htmlString)
  return santizedHTMLString
}

export default function VideoPromoTwo() {
  const [isOpen, setOpen] = useState(false);
  const [title4, setTitle4] = React.useState(false);
  const [description4, setDescription4] = React.useState(false);

  useEffect(()=>{
    const getAllEntries = async () =>{
        try { 
            await client.getEntries({
                content_type: "landingPage"
              }).then((entries) =>{
                console.log(entries.items[0].fields)
            
                setTitle4(entries.items[0].fields.titleSection4)
                setDescription4(getHTMLData(entries.items[0].fields.descriptionSection4))

               
            })
        }  catch(error){
            console.log("error");
        }
    }
    getAllEntries()
  },[])
  return (
    <>
      <section
        id="download"
        className="video-promo ptb-100 background-img"
        style={{
          background:
            "url('assets/img/railway.jpg')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="video-promo-content mt-4 text-center">
              
                <h5 className="mt-4 text-white">{title4}</h5>
                <p style={{color:'whitesmoke',textAlign:'justify'}}> <div dangerouslySetInnerHTML= {{__html: description4}}/></p>

              </div>
            </div>
          </div>
          <ModalVideo
            channel="youtube"
            isOpen={isOpen}
            videoId="9No-FiEInLA"
            onClose={() => setOpen(false)}
          />
        </div>
      </section>
    </>
  );
}
