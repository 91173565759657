import video from './video.mp4'
import React,{useContext, useEffect, useState} from "react";
import { client } from '../../components/team/client';
import {marked} from 'marked'
import DOMPurify from 'dompurify'
import Fade from 'react-reveal/Fade';

export const getHTMLData = (rawdata) => {
  const htmlString = marked(rawdata)
  const santizedHTMLString = DOMPurify.sanitize(htmlString)
  return santizedHTMLString
}

export default function HeroFive() {
  const [header, setHeader] = React.useState(false);
  const [tagline, setTagline] = React.useState(false);

  useEffect(()=>{
    const getAllEntries = async () =>{
        try { 
            await client.getEntries({
                content_type: "landingPage"
              }).then((entries) =>{
                setHeader(entries.items[0].fields.headerTitle)
                setTagline(entries.items[0].fields.headerTagline)
             

               
            })
        }  catch(error){
            console.log("error");
        }
    }
    getAllEntries()
  },[])
  return (
    <>
      <section id='home' className="hero-section position-relative min-vh-100 background-video-overly flex-column d-flex justify-content-center">
        <video
          className="fit-cover w-100 h-100 position-absolute z--1"
          autoPlay
          muted
          loop
          id="myVideo"
        >
          <source
            src={video}
            type="video/mp4"
          />
        </video>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-lg-8" id='titlewidth'>
              <div className="hero-content-left text-white text-center mt-5 ptb-100">
              <Fade duration={2000}>
                <h1 className="text-white">
                {header}
                </h1>
                </Fade>
                <Fade delay={1000}>
                <p className="lead">
                  {tagline}
                </p>
                </Fade>
                <Fade delay={2000}>
                <a href="/#about" className="btn google-play-btn">
                  Read More
                </a>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
