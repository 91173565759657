import React,{useContext, useEffect, useState} from "react";
import { client } from "../team/client";
import {marked} from 'marked'
import DOMPurify from 'dompurify'

export const getHTMLData = (rawdata) => {
  const htmlString = marked(rawdata)
  const santizedHTMLString = DOMPurify.sanitize(htmlString)
  return santizedHTMLString
}

export default function FeatureImgThree() {
  const [title3, setTitle3] = React.useState(false);
  const [subtitle3, setsubTitle3] = React.useState(false);
  const [description3, setDescription3] = React.useState(false);
  const [tarea2, setTArea2] = React.useState(false);
  const [darea2, setDArea2] = React.useState(false);
  const [tarea3, setTArea3] = React.useState(false);
  const [darea3, setDArea3] = React.useState(false);
  const [tarea4, setTArea4] = React.useState(false);
  const [darea4, setDArea4] = React.useState(false);
  const [tarea5, setTArea5] = React.useState(false);
  const [darea5, setDArea5] = React.useState(false);
  const [tarea6, setTArea6] = React.useState(false);
  const [darea6, setDArea6] = React.useState(false);

  useEffect(()=>{
    const getAllEntries = async () =>{
        try { 
            await client.getEntries({
                content_type: "landingPage"
              }).then((entries) =>{
                setTitle3(entries.items[0].fields.thematicAreas)
                setsubTitle3(entries.items[0].fields.thematicArea1)
                setDescription3(getHTMLData(entries.items[0].fields.descriptionArea1))

                setTArea2(entries.items[0].fields.thematicArea2)
                setDArea2(getHTMLData(entries.items[0].fields.descriptionArea2))
                setTArea3(entries.items[0].fields.thematicArea3)
                setDArea3(getHTMLData(entries.items[0].fields.descriptionArea3))
                setTArea4(entries.items[0].fields.thematicArea4)
                setDArea4(getHTMLData(entries.items[0].fields.descriptionArea4))
                setTArea5(entries.items[0].fields.thematicArea5)
                setDArea5(getHTMLData(entries.items[0].fields.descriptionArea5))
                setTArea6(entries.items[0].fields.thematicArea6)
                setDArea6(getHTMLData(entries.items[0].fields.descriptionArea6))
               
            })
        }  catch(error){
            console.log("error");
        }
    }
    getAllEntries()
  },[])
  return (
    <>
      <section id="work" className="feature-section ptb-100">
        <div className="container">
          
          <div  className="row  justify-content-between">
            <div className="col-md-4">
              <div className="download-img">
                <img  style={{borderRadius:'20px',marginTop:'10px'}}
                  src="assets/img/european.jpg"
                  alt="download"
                  className="img-fluid"
                />
                <img  style={{borderRadius:'20px',marginTop:'10px'}}
                  src="assets/img/energy.jpg"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="feature-contents section-heading">
              <h2>{title3}</h2>
              <br></br>
                <div className="feature-content-wrap">
                  <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active h6"
                        href="#tab6-1"
                        data-toggle="tab"
                      >
                        <span className="ti-book"></span>
                        {subtitle3}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-2"
                        data-toggle="tab"
                      >
                        <span className="ti-vector"></span>
                        {tarea2}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-3"
                        data-toggle="tab"
                      >
                        <span className="ti-bolt"></span>
                        {tarea3}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-4"
                        data-toggle="tab"
                      >
                        <span className="ti-map"></span>
                       {tarea4}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-5"
                        data-toggle="tab"
                      >
                        <span className="ti-bar-chart"></span>
                        {tarea5}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-6"
                        data-toggle="tab"
                      >
                        <span className="ti-car"></span>
                        {tarea6}
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content feature-tab-content">
                    <div className="tab-pane active" id="tab6-1">
                    <div dangerouslySetInnerHTML= {{__html: description3}}/>
                   </div>
                   <div className="tab-pane" id="tab6-2">
                   <div dangerouslySetInnerHTML= {{__html: darea2}}/>
                     </div>
                    <div className="tab-pane" id="tab6-3">
                      <div className="row">
                        <div className="col single-feature mb-4">
                        <div dangerouslySetInnerHTML= {{__html: darea3}}/>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="tab6-4">
                    <div dangerouslySetInnerHTML= {{__html: darea4}}/></div>
                    <div className="tab-pane" id="tab6-5">
                    <div dangerouslySetInnerHTML= {{__html: darea5}}/>
                    </div>
                    <div className="tab-pane" id="tab6-6">
                    <div dangerouslySetInnerHTML= {{__html: darea6}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
