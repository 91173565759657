import React from "react";
import BlogCard from "../components/blogs/BlogCard";
import Pagination from "../components/blogs/Pagination";
import FooterTwo from "../components/layout/footer/FooterTwo";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import Features from "../components/features/Features";

import ProjectRight from "../components/features/ProjectRight";

export default function BlogGrid() {
  return (
    <Layout>
      <Navbar darkBg />
      
      <PageHeader
        HeaderTitle="Projects"
     
      />
      
      <ProjectRight/>

      <FooterTwo />
    </Layout>
  );
}
