import React,{useContext, useEffect, useState} from "react";
import { client } from "../team/client";
import { Timeline } from "react-twitter-widgets";
import MyPDF1 from './GENDER-EQUALITY.pdf';
import icon from './pdficon2.png'
import {marked} from 'marked'
import DOMPurify from 'dompurify'

export const getHTMLData = (rawdata) => {
    const htmlString = marked(rawdata)
    const santizedHTMLString = DOMPurify.sanitize(htmlString)
    return santizedHTMLString
}


export default function AboutGoal() {

  const [title2, setTitle2] = React.useState(false);
  const [description2, setDescription2] = React.useState(false);

  useEffect(()=>{
    const getAllEntries = async () =>{
        try { 
            await client.getEntries({
                content_type: "landingPage"
              }).then((entries) =>{
            
                setTitle2(entries.items[0].fields.section2Title)
                setDescription2(getHTMLData(entries.items[0].fields.section2Description))

               
            })
        }  catch(error){
            console.log("error");
        }
    }
    getAllEntries()
  },[])
  return (
    <>
      <section id="about" className="about-us ptb-100 "  style={{backgroundColor:'#b6e3ff38'}}>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              <div className="about-content-left section-heading" >
                <span className="text-uppercase color-secondary sub-title">
                Follow and stay tuned
                </span>
                <br/><br/>
              
                <Timeline
  dataSource={{
    sourceType: 'profile',
    screenName: 'EIfI_Umbrella'
  }}
  options={{width:'500',height:'300'}}
/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-content-right">
               <h5>{title2} <img
                style={{width:'40px',top:'14px',position:'relative'}}
                  src="assets/img/gender-equality.svg"
                  alt="about us"
                  className="img-fluid"
                /></h5>
               <p><div dangerouslySetInnerHTML= {{__html: description2}}/></p> <a className='btn solid-btn' style={{color:'#29659d',padding:'2px 8px',color:'#3074a9',backgroundColor:'white',borderRadius:'5px'}} href={MyPDF1} download="EIfI-TECH_Gender_Eguality.pdf">Read More </a><img style={{width:'22px',top:'8px',position:'relative',marginLeft:'8px'}} src={icon}></img>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
