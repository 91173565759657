import React from "react";

export default function TeamMember() {
  return (
    <>
      <section className="team-single-section ptb-100">
      <div className="section-heading text-center mb-5">
                
                <h2>The Leadership Board</h2>
              
              </div>
        <div className="container">
         
          <br/>
          <div className="row justify-content-center">
            <div className="col-md-12 col-sm-12 col-lg-3" id='chris'>
              <div className="team-single-img">
                <img
                  src="assets/img/ben.png"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1">Ben Daumiller</h4>
                  <span style={{fontWeight:'500'}}>Associate Director</span>
                </div>
                <ul className="team-single-info">
             
             <li className="list-inline-item">
               <a href="https://www.linkedin.com/in/ben-daumiller-5255158b/" className="color-primary">
                 <span className="ti-linkedin"></span>
               </a>
             </li>
             <li>
               <strong>Email:</strong>
               <span> &nbsp;b.daumiller@eifi-tech.eu</span>
             </li>
           </ul>
                <div className="text-content mt-20">
                  <br/>
                  <p>
                  His role as an Associate Director within an institute is multifaceted, involving leadership, strategic planning, operational management, stakeholder engagement, personnel management, and a strong focus on maintaining high academic and research standards. He plays a crucial role in shaping the institute's direction and ensuring its overall success and sustainability.                  </p>
                </div>
               
              </div>
            </div>
          </div>
       
       
              
            
         
        </div>
      </section>
    </>
  );
}


/*
 <div className="row justify-content-center">
            <div className="col-md-12 col-sm-12 col-lg-3" id='chris'>
              <div className="team-single-img">
                <img
                  src="assets/img/chris.png"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1">Chris Ashe</h4>
                  <span style={{fontWeight:'500'}}>Director</span>
                </div>
                <ul className="team-single-info">
             
                  <li className="list-inline-item">
                    <a href="https://twitter.com/chrisashe1" className="color-primary">
                      <span className="ti-twitter"></span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.linkedin.com/in/chris-ashe-07b3b920/" className="color-primary">
                      <span className="ti-linkedin"></span>
                    </a>
                  </li>
                  <li>
                    <strong>Email:</strong>
                    <span> &nbsp;c.ashe@eifi-tech.eu</span>
                  </li>
                </ul>
                <div className="text-content mt-20">
                  <br/>
                  <p>
                  His role extends to international technology/knowledge transfer for wherein he brokers resources for business, education, research and public authorities. Chris Ashe leads a vibrant, dynamic and confident Institution that delivers outstanding performance, and is highly regarded and sought after by partners locally, nationally and internationally. 
                  </p>
                 
                </div>
              
              </div>
            </div>
          </div>
*/